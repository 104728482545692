label.control-label {
  font-weight: bold;
}
label.radio {
  display: inline-block;
  margin-right: 10px;
}

label, input, button, select, textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: if($theme-name != "active_menu", 20px, null);
  border-radius: 0;
}

textarea, input, select {
  border: none;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;

  display: inline-block;
  padding: 4px 6px;
  font-size: 16px;
  line-height: if($theme-name != "active_menu", 20px, null);
  color: #555555;
  vertical-align: middle;
  @include placeholder(#a2a2a2);
  transition: border-bottom-color 0.2s ease-out;

  &:focus {
    outline: none;
    border-bottom-color: #4059a9;
  }
}

input[type="radio"]:focus, input[type='checkbox']:focus {
  box-shadow: 0 0 3px 2px rgba(64, 89, 169, 0.33);
  background-color: rgba(64, 89, 169, 1);
  border-radius: 10px;
}

textarea, input[type='text'], input[type='tel'], input[type='email'], input[type='password']{
  width: 100%;
}

input[type='checkbox'], input[type='radio'] {
  margin-right: 10px;
}

.form-group .help-block  {
  display: block;
  width: 100%;
}

textarea {
  height: 50px;
}

.input-prepend {
  display: flex;
  flex-wrap: wrap;

  input {
    flex: 1;
  }
}

.error {
  textarea, input {
    border-bottom-color: #d10;
  }
  .help-block {
    color: #d10;
  }
}
.success {
  textarea, input {
    border-bottom-color: green;
  }
  .help-block {
    color: green;
  }
}
button[type='submit']:focus, input[type='submit']:focus {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.41), 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}


form.checkout {

  h3 { margin-bottom: 5px; }

  abbr[title='required'] {
    color: #d10;
  }

  header {
    margin-bottom: 15px;
  }

  section {
    padding-top: 35px;
    @extend .card;
  }

  section.error header a {
    color: #d10;
  }

  // this button is only visible on Skubacz
  .btn-search-restaurant {
    display: none;
  }

  .icon-ok {
    font-size: 14px;
    line-height: if($theme-name != "active_menu", 20px, null);
  }

  .actions {
    text-align: right;
  }

  .form-actions {
    margin-top: 0px;
    border-top: none;
    margin-bottom: 15px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }


    .aggreement {

      margin-bottom: 15px;
    }
    .button{
      float: right;
      width: 250px;
    }

  }

  .control-group {
    min-height: 50px;
  }

  section.confirmation {
     li { margin-bottom: 20px; }
   }

   .apt_number .controls {
     display: flex;
     flex-wrap: wrap;

     input[type='checkbox'] {
       margin-top: 7px;
     }

     input[type='text'] {
       width: auto;
       flex-grow: 1;
     }

     .help-block {
       width: 100%;
     }

   }
}
.form-centered {
  max-width: 500px;
  margin: 0 auto;
}

// .form-horizontal {
//   .control-label {
//     width: 155px;
//     float: left;
//   }
//   .controls {
//     margin-left: 170px;
//     position: relative;
//   }
//   .control-group {
//     &:after {
//       content: "";
//       display: table;
//       clear: both;
//     }
//     margin-bottom: 15px;
//   }
// }
.form-bootstrap {
  .control-group {
    margin-bottom: 15px;
  }
}


@media (max-width: 767px) {
  .form-horizontal {
    .control-label {
      width: auto;
      float: none;
    }
    .controls {
      margin-left: 0;
    }
  }
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

[data-max-length] {

}
