.phone-popover {
  hr, h5 {
    margin: 7px 0;
  }
}
.popover-title:empty {
  display: none;
}

.phone-popover-testimonial {
  background: #f3f3f3;
  padding: 10px;
  margin-bottom: 10px;

  border-radius: 10px;

  img {
    width: 80px;
  }

  p {
    font-style: italic;
  }

}
