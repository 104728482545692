// Variables
////////////////////////////////

$m-menu-description-margin: 40px 0 0;
$m-section-margin: 0 0 40px;

// Forms
$form-centered-max-width: 500px;
$form-control-font-size-base: 16px;

// Custom styles
@import "base/shared-essentials";
@import "base/shared-themes";
@import "components/forms";
@import "components/language_select";
@import "components/alerts";
@import "components/buttons";
@import "modules/m-section";
@import "modules/m-page-subheader";
@import "modules/m-checkout";

@mixin stopGradient($firstColor, $secondColor) {
  background: $firstColor; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    $firstColor 1%,
    $firstColor 80%,
    $secondColor 81%,
    $secondColor 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1%, $firstColor),
    color-stop(80%, $firstColor),
    color-stop(81%, $secondColor),
    color-stop(100%, $secondColor)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $firstColor 1%,
    $firstColor 80%,
    $secondColor 81%,
    $secondColor 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    $firstColor 1%,
    $firstColor 80%,
    $secondColor 81%,
    $secondColor 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    $firstColor 1%,
    $firstColor 80%,
    $secondColor 81%,
    $secondColor 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    $firstColor 1%,
    $firstColor 80%,
    $secondColor 81%,
    $secondColor 100%
  ); /* W3C */
}

body {
  font-family: "Open Sans", sans-serif;
  background: white url("../images/solej/1a0bee6ac7d1b36b2f8e3e1839536a1f.png")
    repeat;
  position: relative;
  overflow-x: hidden;

  &.l-navbar-affixed {
    padding-top: $navbar-height;
  }
}
img {
  max-width: 100%;
}

input,
select,
textarea,
button {
  font-family: "Open Sans", sans-serif;
}
img {
  max-width: 100%;
}

.input-prepend .help-block,
.input-prepend .help-block {
  font-size: 14px;
}

#header {
  padding-top: 40px;

  @media screen and (max-width: $grid-float-breakpoint-max) {
    min-height: 120px;
  }
}

#header > .header {
  padding-top: 5px;
  padding-bottom: 20px;
}

.header .logo-container {
  position: relative;

  a {
    display: block;
    position: absolute;
    z-index: 1001;
    max-width: 75%;
    top: 0px;
    left: 20px;
    color: white;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      max-width: 200px;
    }

    .h5 {
      display: block;
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 18px;
      font-weight: normal;
    }
    p {
      margin-bottom: 0px;
    }

    .logo-frame {
      background: white;
      padding: 10px;
      box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);

      @media screen and (max-width: $screen-sm-max) {
        display: inline-block;
        max-width: 35vw;
      }

      .logo {
        @media screen and (max-width: $screen-sm-max) {
          display: block;
          max-height: 80px;
          width: auto;
        }
      }
    }
  }

  .logo-container__header {
    margin: 0;
    font-size: inherit;
    text-transform: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.header .restaurant-name a {
  text-align: center;
  text-decoration: none;
  color: #fff;
}

.header .restaurant-name p {
  margin: 0;
}

.header .restaurant-controls {
  text-align: right;
  text-transform: uppercase;

  .social-list__link {
    font-size: 22px;
  }
}

.social-list {
  padding-left: 0;
  list-style: none;

  li {
    display: inline-block;
  }

  &__link {
    vertical-align: middle;
    text-decoration: none;

    [class^="icon-"]:before {
      vertical-align: baseline;
    }
  }
}

ul.restaurant-controls > li {
  display: inline-block;
  line-height: 1.2em;
}

.header .restaurant-controls a {
  color: rgba(255, 255, 255, 0.35);
  font-size: 14px;
}

.navigation > ul {
  margin: 0;
  float: right;
}

.navigation > ul > li {
  padding: 0;
  margin: 0 0 0 5px;
  list-style: none;
  line-height: 50px;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin: 0;
  }
}

.navigation > ul > li > a {
  display: inline-block;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 20px;

  .caret {
    vertical-align: middle;
    margin-top: 0;
    border-top-color: rgba(255, 255, 255, 0.8);
  }

  &:hover,
  &:focus,
  &.active {
    text-decoration: none;
  }
}

.dropdown-menu {
  z-index: 10003;
}

.navigation a.active {
  color: #fff;
  font-weight: bold;
}

#footer {
  position: relative;
}

.footer-details {
  font-size: 12px;
  padding: 40px 0;

  h5 {
    font-size: 18px;
    font-weight: normal;
  }

  .logo-span {
    text-align: center;
    a {
      max-width: 100%;
    }
  }

  a.button {
    padding: 4px 10px;
    font-size: 14px;
    width: 90%;
    text-align: center;
  }

  .logo {
    padding: 10px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  }
}

.footer-details a {
  color: #fff;
}

.footer-copy {
  padding: 10px 0;
  font-size: 12px;
  color: #fff;
}

.social {
  margin-left: 10px;
  font-size: 20px;
}

.social a {
  color: white;
  text-decoration: none;
}

.content-background {
  background: #f3f3f3;
}

.content-content {
  margin-bottom: 50px;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.section {
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  .splide {
    margin-bottom: -100px;

    .m-indicators {
      margin-top: -100px;
    }
  }

  &.mobile-app-promo {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.funky-border {
    background-color: darkgreen;
  }

  .section-header {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      font-size: 30px;
      text-transform: uppercase;
    }

    p {
      font-size: 20px;
      line-height: 1.2em;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
  }

  .actions {
    margin-top: 70px;
    text-align: center;
  }

  &.steps {
    padding-top: 50px;

    .step {
      text-align: center;
      position: relative;
    }
    .section-header {
      margin-bottom: 65px;
    }

    .img-circle {
      @extend .flex-box-center;
      width: 123px;
      height: 123px;
    }

    .badge {
      border-radius: 500px;
      padding: 0 9px;
      vertical-align: middle;
      position: absolute;
      font-size: 18px;
      line-height: 28px;
      top: -15px;
      margin-left: -14px;
      left: 50%;
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .row-fluid p {
      font-size: 12px;
      font-weight: bold;
      line-height: 18px;
    }
  }

  &.testimonials {
    padding-top: 60px;
    padding-bottom: 50px;

    .col-md-4 {
      text-align: center;
    }

    .section-header {
      margin-bottom: 120px;
    }
    .img-circle {
      width: 100px;
      height: 100px;
    }
    .actions {
      margin-top: 60px;
    }

    blockquote {
      padding: 20px;
      text-align: left;
      position: relative;
      border-left: none;
      height: 120px;

      cite {
        font-weight: bold;
        font-size: 12px;
      }

      p {
        font-size: 12px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid;
        color: rgba(255, 255, 255, 0.9);
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        bottom: -10px;
        width: 0px;
        left: 50%;
        z-index: 10;
        margin-left: -10px;

        border-width: 10px 10px 0;
        border-style: solid;
      }
    }
  }

  &.hours {
    padding-top: 70px;
    padding-bottom: 50px;

    .image-span {
      text-align: center;
      position: relative;

      @media screen and (max-width: $grid-float-breakpoint-max) {
        margin-bottom: $grid-gutter-width;
      }
    }

    .button {
      margin-top: -30px;
    }

    .img-circle {
      width: 260px;
      height: 260px;
    }

    h5 {
      font-size: 20px;
    }

    dd {
      margin-bottom: 35px;
    }

    p.muted {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.8);
    }

    .img-frame {
      display: inline-block;
      border-radius: 500px;
      padding: 5px;
    }
  }

  &.promotions .carousel-inner .item {
    margin-top: 40px;
    margin-bottom: 40px; // safe space for overflow elements, e.g. action button
    height: 270px;
  }
  .promotion-box {
    padding: 50px 100px;
    background: white;
    color: black;
    border-radius: 100px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    position: relative;

    .promotion-text {
      font-size: 21px;
      line-height: 30px;
      margin-right: 50px;
      max-height: 135px;
      overflow: hidden;
    }

    a.button {
      position: absolute;
      bottom: -28px;
      left: 50%;
      margin-left: -75px;
    }

    .img-circle {
      color: white;
    }

    .promo-badge.img-circle {
      text-transform: uppercase;
      position: absolute;
      top: -20px;
      left: -20px;
      width: 100px;
      height: 100px;
      @extend .flex-box-center;
    }

    .price-badge.img-circle {
      padding: 5px;
      position: absolute;
      right: -50px;
      top: 50%;
      margin-top: -100px;
      width: 200px;
      height: 200px;
      @extend .flex-box-center;

      .price {
        font-size: 53px;
        font-weight: bold;
      }
      .price.reduction {
        font-size: 76px;
      }

      .price.text {
        font-size: 112px;
      }
    }
  }

  &.three-boxes {
    padding-top: 145px;
  }

  &.referral {
    text-align: center;
    padding-top: 170px;
    padding-bottom: 170px;

    .container > div {
      background: white;
      color: black;
      padding: 0px 20px;
    }

    p {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .img-circle {
      padding: 10px 15px;
      display: inline-block;
      margin-top: -40px;
    }

    a.button-inverse {
      margin-bottom: -55px;
    }
  }

  &.newsletter {
    text-align: center;

    h3 {
      margin-bottom: 15px;
    }

    .img-circle {
      margin-top: -50px;
      margin-bottom: 40px;
      padding: 21px;
    }

    a.button {
      margin-bottom: 40px;
    }

    .social-icons {
      margin-bottom: -25px;

      a {
        min-width: 32px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 9px;
        display: inline-block;

        &.disabled {
          opacity: 0.6;
          cursor: default;
          text-decoration: none;
          pointer-events: none;
        }
      }
    }

    .alert-success {
      margin-bottom: 15px;

      i {
        color: green;
      }
      display: inline-block;
    }

    input {
      border-radius: 0;
      padding: 10px;
      margin-bottom: 20px;
      max-width: 297px;
    }

    form {
      margin-bottom: 40px;
    }
  }

  &.hours {
    .opening-hours {
      font-size: 16px;
      text-align: center;

      &__group {
        margin-bottom: ($line-height-computed / 2);

        .list-unstyled {
          margin-bottom: 0;
        }
      }
    }
  }
}

.section-header::after,
.testimonials blockquote::after,
#newsSlider .item .slider-content::after,
.box.white::after {
  content: "";
  clear: both;
  display: block;
}

#newsSlider .item {
  background-repeat: no-repeat;
}

@mixin gradient($direction, $startColor, $endColor) {
  background-image: linear-gradient(to $direction, $startColor, $endColor);
}

.splide,
.splide__track,
.splide__list {
  height: 100%;
}

.carousel-inner {
  .item {
    width: 100%;
    height: 100%; // hero fix to ensure the overlay covers the full height in variant with bg image or video
  }
}

#newsSlider .carousel-inner:not(.splide__list) {
  height: 587px;
  overflow-y: hidden;
}

#newsSlider .item {
  height: 587px;
  background-size: cover;

  &:not(:first-of-type) {
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .slider-content {
    height: 100%;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: flex;
      align-items: center;
      height: auto;
      min-height: calc(
        100vh - 140px
      ); // full height size including the header and part of the footer
    }

    &__main {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        width: 100%;
        float: none;
      }
    }

    &__aside-media-right,
    &__aside-media-left {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }

    &__aside-media-right {
      @media screen and (min-width: $screen-lg-min) {
        margin-right: -10vw;
        width: calc(33% + 10vw);
        text-align: left;

        .secondary-image {
          margin-right: auto;
        }
      }
    }

    &__aside-media-left {
      @media screen and (min-width: $screen-lg-min) {
        margin-left: -10vw;
        width: calc(#{100% / 3} + 10vw);
        text-align: right;

        .secondary-image {
          margin-left: auto;
        }
      }
    }
  }

  .flex-box-center {
    height: 550px;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      height: auto;
    }
  }

  @media screen and (max-width: $grid-float-breakpoint-max) {
    height: auto;
  }

  @at-root .carousel {
    &--hero {
      .item {
        @media screen and (min-width: $grid-float-breakpoint) {
          overflow: hidden;
        }
      }
    }
  }
}

.jumbotron {
  margin-top: 100px;
  padding-left: 0;
  padding-right: 0;
  background: none;
  color: white;
  text-shadow: 0 1px black;

  .h1 {
    margin-bottom: 10px;
    hyphens: auto;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      font-size: 36px;
    }
  }

  .button {
    margin-top: 10px;
    text-shadow: none;
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  @media screen and (max-width: $grid-float-breakpoint-max) {
    margin-top: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@import "components/phone_popover";

.text-promo {
  margin-top: 0;
  margin-bottom: 0;
  max-width: inherit;
}

.promo-box-top {
  height: auto;
}

.promo-box-top.one-box-left .box {
  position: relative;
  max-width: 70%;
}

.promo-box-top.bubbles {
  top: -220px;
  height: 220px;
  width: 100%;
  position: absolute;
}

.flex-box-center {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  /* vertical centering for legacy, horizontal centering for modern */
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  /* modern Flexbox only */
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  /* legacy Flexbox only */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
}

.button {
  background: white;
  border: none;
  display: inline-block;
  color: #178023;
  text-transform: uppercase;
  padding: 7px 10px;
  font-size: 14px;
  border: 1px solid #178023;
  border-radius: 0;
  box-shadow: 0 0 0 4px white;

  &.button-inverse {
    background: #178023;
    color: white;
    border: 1px solid #2da038;
    box-shadow: 0 0 0 4px #178023;
  }

  &.button-large {
    font-size: 18px;
    line-height: 22px;
    padding: 16px 18px;
    font-weight: bold;
    box-shadow: 0 0 0 4px white;

    &.button-inverse {
      box-shadow: 0 0 0 4px #178023;
    }
  }

  &.button-square {
    width: 98px;
    height: 98px;
    font-size: 18px;
    line-height: 22px;
    padding: 5px;

    @extend .flex-box-center;
  }

  // Apply primary styles to the order button in the checkout.
  &.raised {
    @extend .button-inverse;
  }
}

.text-promo .button-container a.button {
  @extend .button;
}

.promo-box-top.bubbles .button-container a.button,
.promo-box-top.bubbles .text-promo .button-container a.button,
.text-promo .promo-box-top.bubbles .button-container a.button {
  @extend .button;
}
.promo-box-top.one-box-left a.button {
  @extend .button;
}
.promo-box-top.two-boxes-left .boxes > .box .button-container > a.button {
  @extend .button;
}

.carousel {
  position: relative;
}

.m-indicators {
  position: absolute;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  top: 20px;
  right: 100px;
  left: auto;
  bottom: auto;
  z-index: 100;
  text-align: right;
  justify-content: flex-end;

  &__list {
    display: block;
    align-items: center;
    counter-reset: pagination-num;
  }

  &__btn {
    &:empty {
      &:before {
        display: inline-block;
        padding: 4px;
        counter-increment: pagination-num;
        content: counter(pagination-num);
      }
    }
  }

  &__btn {
    @extend .button;
    text-indent: inherit;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin: 0 3px;
    padding: 0;
    vertical-align: middle;
    box-shadow: none;
    cursor: pointer;
  }
  &__btn.is-active {
    @extend .button-inverse;
    box-shadow: none;
  }

  &__btn.button-item {
    width: auto;
    height: auto;

    a {
      display: inline-block;
      padding: 5px;
      color: inherit;
    }
  }
}

.box.white {
  border: 1px solid #e2e2e2;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.box.white h3 {
  font-size: 18px;
  line-height: 20px;
}

.box.white .promotion-content {
  padding: 10px 20px;
  margin-left: 200px;
}

.box-column {
  text-align: center;
  position: relative;
  padding: 45px;
  margin-bottom: 130px;

  img {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -50px;
    height: 100px;
    width: 100px;
  }

  a.button {
    position: absolute;
    bottom: -55px;
    left: 50%;
    margin-left: -55px;
  }
}

.box.page {
  margin-bottom: 50px;
  padding: 40px 20px;
}

.box.page h2 {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  line-height: 20px;
}

.box-header {
  text-align: center;
}

.page-header {
  padding: 0;
  margin: 0;
  border: none;
}

.page-header {
  &__background {
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .l-checkout & {
      @media (max-width: $grid-float-breakpoint-max) {
        height: 100px;
      }
    }
  }

  &__interlace {
    width: 100%;
    height: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAFCAYAAABmWJ3mAAAAFElEQVQIW2NkYGDwAeIt6DQjHSQAiPEPBqmZHakAAAAASUVORK5CYII=)
      repeat;
    text-align: center;

    h1 {
      margin: 0;
      padding: 48px;
      text-transform: uppercase;
      color: white;
      text-shadow: 0 1px 0 black;
    }
  }
}

.page-header .menu {
  line-height: 50px;
  height: 50px;
  background: #041e1d;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  color: #fff;
}

.page-header .menu .phone {
  text-align: center;
}

.basket-menu {
  text-align: right;
}

.basket-menu li {
  list-style: none;
  display: inline-block;
  padding-left: 10px;
}

.tick {
  padding-right: 10px;
}

.box.page .details {
  margin-top: 40px;
  border: 1px solid #e2e2e2;
  padding: 20px;
}

.box.page .details .column-detail {
  width: 50%;
  float: left;
}

.tour-backdrop {
  opacity: 0.2;
}

.popover.tour {
  .popover-header {
    background: black;
    color: white;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #e2e2e2;
  }

  .popover-body {
    text-align: center;
  }
  &.first-step {
    .popover-navigation *[data-role="end"] {
      display: none;
    }
    .popover-navigation *[data-role="next"] {
      float: none;
      width: 100%;
    }
  }

  .popover-navigation *[data-role="end"] {
    float: left;
    line-height: 29px;
  }
  .popover-navigation *[data-role="next"] {
    float: right;
  }
  .popover-navigation *[data-role="restart"] {
    float: right;
  }
}

/*********  Menu restauracji **************/

.restaurant-menu {
  .m-group-nav {
    &__link {
      &--dropdown-toggle {
        padding-top: $navbar-padding-vertical;
        padding-bottom: $navbar-padding-vertical;
        line-height: $line-height-computed;
      }
    }

    &__list {
      &--dropdown {
        margin-top: 0;
      }
    }

    &--tabs {
      .m-group-nav__link {
        font-size: inherit;
      }
    }
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  #sticky_header {
    .navbar-nav {
      margin: 0;
    }

    .cart-button {
      margin-right: 10px;
      font-size: 25px;
    }

    .nav li {
      display: inline-block;
    }
  }
}

#sticky_header.is-affixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-navbar-fixed;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInDown $base-duration;
}

.no-pie-choice .dropdown-menu,
.no-pie-choice .caret {
  display: none;
}

a[data-toggle="tab"] {
  text-transform: uppercase;
}

.table.menu {
  & > thead {
    background: #f3efee;
    th {
      text-align: center;
      vertical-align: middle;
    }
    th.product-name {
      text-align: left;
    }
  }
  td {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.table thead th.size-info {
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);

  .size-in-cm {
    font-size: 10px;
    color: rgb(187, 182, 182);
  }
}

.product-name {
  text-align: left;
}

.cart-count {
  display: inline-block;
  color: green;
}

td.price {
  width: 55px;
  text-align: center;
}

a.configure {
  font-size: 12px;
  color: rgba(0, 141, 210, 0.77);
  font-weight: bold;
}

.product-name h3,
.product-name h4 {
  font-size: 14px;
  color: black;
  margin: 0;
}

.product-name .muted {
  font-size: 12px;
  color: #746d6d;
}

.add-button {
  font-weight: bold;
}

.navbar {
  margin-bottom: 0;
  border: none;
}

.navbar-inverse .divider-vertical {
  border-left-color: transparent;
  border-right-color: transparent;
}

.navbar-inverse .pull-right.inline {
  padding: 10px 0 0;
}

.navbar-inverse .pull-right.inline a {
  color: white;
}

.navbar-inverse .brand,
.navbar-inverse .nav > li > a {
  color: #d7d7d7;
}

.item-counter {
  position: absolute;
  top: 6px;
  right: 0px;
  line-height: 15px;
  font-size: 10px;
  padding: 0px 4px;
  border-radius: 100px;
  background: rgb(2, 167, 245);
  color: white;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
}

.navbar-inverse .nav > li > h4 {
  color: white;
}
.navbar-inverse .nav li.dropdown > .dropdown-toggle.show {
  background: transparent;
}

.navbar-inverse .nav > li > a#checkout_shortcut {
  cursor: pointer;
  margin: 10px 5px;
  border: none;
  padding: 5px;
  color: white;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.container > .content {
  margin-top: 30px;
}

/************************ Strona z promocjami ************************/

.site-promotions {
  text-align: center;

  .content-content {
    background: transparent;
    box-shadow: none;
  }

  .content-header .h1,
  .h2 {
    font-size: 30px;
    text-transform: uppercase;
  }
  .control-label {
    display: none;
  }
  .controls {
    margin-left: 0;
  }

  ul.unstyled {
    font-size: 16px;
    background: white;
    padding: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

    li {
      line-height: 22px;
    }
  }
  .promotions {
    position: relative;
    z-index: 0;
  }
  .content-content,
  .promotions {
    max-width: 900px;
    margin: 0 auto;
  }

  .promotion {
    margin-top: 150px;
    text-align: center;

    background: white;
    position: relative;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);

    &:before,
    &:after {
      position: absolute;
      width: 25%;
      height: 10px;
      content: " ";
      left: 50px;
      bottom: 9px;
      background: transparent;
      transform: skew(-5deg) rotate(-5deg);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
      z-index: -1;
    }

    &:after {
      left: auto;
      right: 50px;
      transform: skew(5deg) rotate(5deg);
    }

    &:last-of-type {
      margin-bottom: 150px;
    }
  }

  .promotion-image {
    margin-top: -100px;
  }
  .promotion-image img {
    width: 140px;
    border-radius: 500px;
  }

  .promotion-content {
    padding: 20px;
  }

  a.button {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    bottom: -15px;
    width: 100px;
  }
}

/*************  Promocje na menu ****************/

.site-promotions.menu-promotions {
  z-index: 1;
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;

  .popover {
    max-width: 330px;
  }

  .close {
    position: relative;
    z-index: 25;
    opacity: 1;
  }

  .text-center {
    font-size: 18px;
  }

  .when-shown {
    display: none;
  }
  .when-hidden {
    display: block;
  }

  &.is-shown {
    .when-shown {
      display: block;
    }
    .when-hidden {
      display: none;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 30px;
  }

  .promotion h2 {
    font-size: 18px;
  }

  .promotion:last-of-type {
    margin-bottom: 0;
  }

  .add-to-cart-info {
    background: green;
    padding: 10px;
    color: white;
    border-radius: 5px;
    text-align: center;
  }
}

/********************** Opinie *****************************/

.stars {
  color: rgb(255, 227, 21);
  text-shadow: 0 0px 1px rgb(97, 35, 35);

  .icon-star-empty {
    text-shadow: none;
    color: #dfdfdf;
  }
}

.stars i {
  margin-right: 2px;
}

.site-reviews {
  max-width: 800px;
  margin: 0 auto;
}

@mixin timeline($firstColor, $secondColor, $borderColor) {
  ul.timeline {
    list-style: none;
    padding: 30px 0 50px 0;
    position: relative;
    margin: 0;

    border-top: 5px solid $borderColor;

    &:before {
      content: "";
      position: absolute;
      width: 5px;
      height: 100%;
      top: 0;
      left: 165px;
      background: $borderColor;
    }

    .review {
      position: relative;
      margin-bottom: 100px;

      label,
      input[type="radio"] {
        width: 24px;
        height: 24px;
        left: 158px;
        top: 20px;
        position: absolute;
        display: block;
      }

      label:after {
        font-family: FontAwesome;
        content: "\e802";
        background: #fff;
        border-radius: 50%;
        color: $firstColor;
        font-size: 26px;
        height: 100%;
        width: 100%;
        left: -2px;
        top: -3px;
        line-height: 24px;
        position: absolute;
        text-align: center;
      }

      input[type="radio"] {
        opacity: 0;
        z-index: 10;
        cursor: pointer;

        left: 0;
        top: 0;
      }

      input[type="radio"]:checked + label:after {
        content: "\f058";
        color: $secondColor;
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.8);
      }

      .thumb,
      .thumb span,
      .review-content h3 {
        transition: all 0.3s ease-in-out 0.2s;
      }

      .thumb {
        margin-bottom: 20px;
        width: 100px;
        height: 100px;
        box-shadow: 0 0 0 8px $firstColor, 0 1px 1px rgba(255, 255, 255, 0.5);
        background-repeat: no-repeat;
        border-radius: 50%;
        transform: scale(0.8) translateX(24px);

        @media screen and (min-width: $screen-xs-min) {
          position: absolute;
          margin-bottom: 0;
        }

        span {
          color: $firstColor;
          width: 100%;
          left: 0;
          text-align: center;
          font-weight: 700;
          font-size: 15px;
          text-transform: uppercase;
          position: relative;
          bottom: -15px;

          @media screen and (min-width: $screen-xs-min) {
            position: absolute;
            bottom: -30px;
          }
        }
      }

      input[type="radio"]:checked ~ .thumb {
        transform: scale(1);
        box-shadow: 0 0 0 8px $secondColor, 0 1px 1px rgba(255, 255, 255, 0.5);

        span {
          color: $secondColor;
        }
      }

      .review-content {
        position: relative;
        display: inline-block;
        padding: 0 20px;
        color: #333;
        border: none;
        border-left: 5px solid $firstColor;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        background: #fff;

        @media screen and (min-width: $screen-xs-min) {
          margin-left: 200px;

          &:before {
            font-family: FontAwesome;
            content: "\25c2";
            font-weight: normal;
            font-size: 54px;
            line-height: 54px;
            position: absolute;
            width: 30px;
            height: 30px;
            color: $firstColor;
            left: -22px;
            top: 5px;
            z-index: 0;
          }
        }

        @media screen and (min-width: $grid-float-breakpoint) {
          margin-left: 230px;
        }

        .stars {
          display: inline-block;
          font-size: 22px;

          @media screen and (min-width: $grid-float-breakpoint) {
            font-size: inherit;
          }
        }
      }

      input[type="radio"]:checked ~ .review-content {
        border-color: $secondColor;

        .review-text {
          display: block;
        }

        &:before {
          color: $secondColor;
        }
      }
    }
  }
}

blockquote.review-comment {
  margin-top: 17px;
  margin-left: 19px;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin-left: 38px;
  }
}

blockquote.review-comment p {
  font-size: 12px;
}

.restaurant.panel:hover {
  box-shadow: 0 0 20px #105d5a;
}

/********************** galeria ********************/

.gallery-link {
  img {
    height: 239px;
  }
}

.gallery img.media-object {
  border: 1px solid #105d5a;
}

.gallery .media > .pull-left {
  margin-right: 25px;
}

.gallery .media {
  margin-top: 25px;
}

.gallery .media a.highlight {
  margin: 20px;
}

/********************* contact ********************/
.street-view {
  text-align: center;
}

.street-view img {
  width: 100%;
}

/********************** order form ****************/
.panel.checkout {
  background: white;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.alert-danger .btn-search-restaurant {
  display: none;
}

form#checkout strong + br {
  display: none;
}

form#checkout .alert-danger br + br {
  display: none;
}

/********************** reservation ***************/

.help-block.highlight {
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(182, 35, 35);
}

/********************** order waiting *************/

.alert-success.waiting-info {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 75px;
}

.waiting-info #counter {
  height: 77px;
  overflow: hidden;
  width: 222px;
  margin: 0 auto;
}

.waiting-info .timer {
  margin-bottom: 16px;
}

.cancel-info h3 {
  margin-top: 0;
}

/********************** Popup telefon ***********/
sup.highlight {
  color: #d10;
}

/********************** efekty  *****************/

@mixin funky-border($themeName) {
  .funky-border {
    position: relative;
    border: none;
  }

  #newsSlider .item:after,
  .funky-border:after {
    position: absolute;
    top: 0px;
    content: "";
    width: 100%;
    height: 7px;
    z-index: 200;
    display: block;
  }

  #newsSlider .item:before,
  .funky-border:before {
    position: absolute;
    bottom: 0px;
    content: "";
    width: 100%;
    height: 7px;
    z-index: 200;
    display: block;
  }

  .page-header.funky-border:before {
    background: url("../images/solej/9bf5a8d639e8f4c6041218070c8a3699.png") 0px
      18px repeat;
  }

  .funky-border.border-bottom:after {
    display: none;
  }
}

.pay-online {
  background: white url("../images/solej/10dc0865cae0c0e19c8e347c2eaa5cbe.png")
    repeat-x 10px;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.4);

  text-align: center;

  .bank-box {
    margin: 30px;
    padding: 5px 35px;
    display: inline-block;
    background: rgba(8, 8, 8, 0.7);
  }

  h4 {
    font-size: 30px;
    font-weight: normal;
    line-height: 36px;
    color: white;
    a {
      text-decoration: underline;
    }
  }

  &--global {
    background: white
      url("../images/solej/e07fd2832ac0698d2379c802e6785d89.png") repeat-x 0 50%;
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  .navbar-inverse .nav > li > a#checkout_shortcut {
    padding-right: 0;
  }
  .navbar-inverse .dropdown > a.dropdown-toggle {
    padding-right: 0;
  }

  #pizzas {
    th.size-info {
      display: none;
    }

    td.price {
      display: none;
    }
  }

  .navigation > ul {
    margin-left: 0;
  }

  .footer-details {
    text-align: center;

    [class*="col"] {
      margin-bottom: floor($base-spacing / 2);
    }
  }

  .content-menu .sidebar > div {
    margin-right: 0px;
  }

  .navigation {
    height: auto;
    float: right;
    color: #fff;

    .icon-reorder {
      font-size: 25px;
    }
  }

  .offset1 {
    margin-left: 0;
  }
}

.panel.summary {
  background: white;
  padding: 20px;
}

.dish-type-image {
  img {
    max-height: 150px;
  }
}

@mixin steps($name, $numbersColor, $iconColor) {
  .steps {
    .badge {
      border: 1px solid $numbersColor;
      background: white;
      color: $numbersColor;
    }

    .svg-curved-arrow {
      content: "";
      display: block;
      right: -75px;
      top: 23px;
      height: 46px;
      width: 147px;
      position: absolute;
      z-index: 1;
      color: $numbersColor;
    }

    .step + .step .svg-curved-arrow {
      top: 65px;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      .step .svg-curved-arrow {
        display: none;
      }
    }

    .step:last-of-type .svg-curved-arrow {
      display: none;
    }

    .img-circle {
      background: white;
    }

    i {
      color: $iconColor;
    }
  }
}

@mixin testimonials($blockQuoteColor, $borderColor) {
  .testimonials {
    blockquote {
      background: $blockQuoteColor;
      color: white;
      p {
        border-bottom-color: white;
      }
      .cite {
        font-weight: bold;
      }

      &:after {
        border-color: $blockQuoteColor transparent;
      }
    }

    .img-circle {
      border: 1px solid $borderColor;
    }
  }
}

@mixin hours($iconColor, $borderColor) {
  .hours {
    .img-frame {
      border: 1px dashed $borderColor;
    }

    img {
      border: 2px solid $borderColor;
    }

    dl i {
      color: $iconColor;
    }
  }
}

@mixin newsletter($borderColor, $iconColor, $iconBackground) {
  .newsletter {
    .container > div {
      border: 1px dashed $borderColor;
    }

    .img-circle {
      border: 1px dashed $borderColor;
      background: $iconBackground;
      display: inline-block;
      box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.5);
    }

    .social-icons a {
      background: $iconBackground;
      border-radius: 500px;
      border: 1px dashed $borderColor;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    }

    .button {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.8);
      border-color: white;
    }

    i {
      color: $iconColor;
    }
  }
}

@mixin button-colors($primaryColor) {
  .button {
    border-color: $primaryColor;
    color: $primaryColor;

    &.button-inverse {
      background: $primaryColor;
      color: white;
      border-color: white;
      box-shadow: 0 0 0 4px $primaryColor;
    }
  }
}

@mixin theme($name, $primaryColor, $secondaryColor, $iconBackgroundColor) {
  .solej-#{$name} {
    .item-counter {
      color: white;
      background: $secondaryColor;
    }

    .content-background {
      background-image: url("../images/solej/29819d8523e60b87dea7b95c53d0b7b1.png");
    }

    .navigation a.active {
      border-bottom: 2px solid $primaryColor;
    }

    @include steps($name, $secondaryColor, $primaryColor);
    @include testimonials($secondaryColor, $primaryColor);
    @include hours($secondaryColor, $primaryColor);

    @include newsletter($primaryColor, $secondaryColor, $iconBackgroundColor);
    @include button-colors($primaryColor);

    .promotion-box {
      .img-circle {
        background: $secondaryColor;
        box-shadow: 0 0 0 3px $secondaryColor;
        border: 1px dashed white;
      }
    }

    .box-column {
      background: $secondaryColor;
      color: white;

      img {
        background: $iconBackgroundColor;
        border: 1px dashed $secondaryColor;
      }
    }

    .referral {
      .img-circle {
        background: $secondaryColor;
        box-shadow: 0 0 0 3px $secondaryColor;
        border: 1px dashed white;
        color: white;
      }
    }

    .dish-type-image {
      border: 1px solid $primaryColor;
    }

    .table.menu thead {
      background: #fbf3c4;
    }

    .btn.checkout {
      color: white;
      background: $primaryColor;
      border: none;
      text-shadow: none;
      box-shadow: none;
    }

    .add-button.show {
      background: $secondaryColor;
    }

    .add-button {
      color: white;
      background: $primaryColor;
      border: none;
      text-shadow: none;
      box-shadow: none;

      & > .caret {
        border-top-color: white;
      }

      & > small {
        color: white;
      }
    }

    .pizza .add-button {
      color: #e4e4e4;
    }

    .menu-promotions {
      .promotion h2 {
        color: $primaryColor;
      }
    }

    #footer {
      padding-top: 25px;
    }

    .footer-details {
      color: white;

      a.button {
        background: $secondaryColor;
        color: white;
        border: none;
        box-shadow: none;
      }
    }
    .dropdown-menu > li > a.active {
      background: $secondaryColor;
    }
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus,
    .dropdown-menu > li > a.is-active,
    .dropdown-submenu:hover > a,
    .dropdown-submenu:focus > a {
      background: $primaryColor;
      color: white;
    }

    .site-reviews {
      @include timeline($primaryColor, $secondaryColor, #e3e3e3);
    }

    @include funky-border($name);

    .m-indicators .m-indicators__btn,
    .m-indicators .m-indicators__btn.is-active {
      box-shadow: none;
    }

    .pay-online a {
      color: $primaryColor;
    }

    .mobile-navigation {
      &__list {
        a {
          &:not(.active) {
            color: $primaryColor;
          }

          &.active {
            background-color: $primaryColor;
            color: inherit;

            @if $name == "dark" {
              @at-root .solej-#{$name}
                  .mobile-navigation__list
                  ul.dropdown-menu {
                background-color: darken($mobile-navigation-bg-color, 5%);
              }
            }
          }
        }
      }
    }
  }
}

@include theme("agat", #168022, #e8b007, #162512);
@include theme("bordo", #d00e0e, #d00e0e, #460606);
@include theme("graywolf", #af1a20, #af1a20, #f5f5f5);
@include theme("terra", #de4619, #de4619, #f1c368);
@include theme("dark", #1d3551, #656b77, #0c1117);

/**************** customizations ***********/

/* agat */
.solej-agat {
  $primaryColor: #168022;

  background: black url("../images/solej/7c7835c8586dbf8c6f1d4cbdd6dd1f02.jpg")
    repeat;

  .restaurant-controls,
  .restaurant-controls a {
    color: rgba(255, 255, 255, 0.7);
  }

  #header {
    background: transparent
      url("../images/solej/0876f5d710e2a03d2d2e7437f2551a71.png") repeat-x;
  }

  #newsSlider .item:after,
  .funky-border:after {
    background: url("../images/solej/6e63cd534b9436bc27d93872fb152cfa.png") 0px
      7px repeat;
  }

  #newsSlider .item:before,
  .funky-border:before {
    background: url("../images/solej/6e63cd534b9436bc27d93872fb152cfa.png") 0px
      20px repeat;
  }

  .steps .step:after {
    background: transparent
      url("../images/solej/bcd67946bc206b25c9d352e5e170cd24.png");
  }

  .navbar-inverse {
    background: #0a5a1d
      url("../images/solej/1d9c11e10861470238c38a728b7f19a4.png");
  }

  .menu-group-header {
    color: white;
    background: #0a5a1d
      url("../images/solej/1d9c11e10861470238c38a728b7f19a4.png");
  }

  #footer {
    background: transparent
      url("../images/solej/0876f5d710e2a03d2d2e7437f2551a71.png") repeat-x;
  }

  .footer-details {
    background: $primaryColor
      url("../images/solej/1d9c11e10861470238c38a728b7f19a4.png");
  }

  .mobile-navigation {
    &__header {
      background: $primaryColor
        url("../images/solej/1d9c11e10861470238c38a728b7f19a4.png") repeat;
    }
  }
}
/* *** */

/* bordo */
.solej-bordo {
  $primaryColor: #d00e0e;
  $darkRed: #460606;

  background: black url("../images/solej/59d42089a126c4470da064033f503001.jpg")
    repeat;

  .restaurant-controls,
  .restaurant-controls a {
    color: rgba(255, 255, 255, 0.7);
  }

  .steps i {
    color: $darkRed;
  }

  .testimonials {
    .section-header {
      text-shadow: 0 2px 13px rgba(0, 0, 0, 0.5);
    }
    blockquote p {
      border-color: black;
    }
  }

  .promotion-box {
    @include button-colors(#168022);
  }

  .three-boxes {
    .button-inverse {
      background: white;
      color: $primaryColor;
      border-color: $primaryColor;
      box-shadow: 0 0 0 4px white;
    }
  }

  .referral .img-circle {
    background: $darkRed;
    color: white;
    border-color: $primaryColor;
    box-shadow: 0 0 0 3px $darkRed;
  }

  .newsletter {
    i.icon-envelope-alt,
    .social-icons a i.icon-twitter {
      color: white;
    }
  }

  #header {
    background: transparent
      url("../images/solej/b66df0826ca93082d6f200f684587641.png") repeat-x;
  }

  #newsSlider .item:after,
  .funky-border:after {
    background: url("../images/solej/802305e4f673e4a3c161557661806144.png") 0px
      7px repeat;
  }

  #newsSlider .item:before,
  .funky-border:before {
    background: url("../images/solej/802305e4f673e4a3c161557661806144.png") 0px
      20px repeat;
  }

  .steps .step:after {
    background: transparent
      url("../images/solej/71fd27ee45ac4c7dc9ddde9fcc6114c0.png");
  }

  .navbar-inverse {
    background: #0a5a1d
      url("../images/solej/51ef8d3cb7e7278d7a685017bcfaa92b.png");
  }

  .menu-group-header {
    color: white;
    background: #0a5a1d
      url("../images/solej/51ef8d3cb7e7278d7a685017bcfaa92b.png");
  }

  #footer {
    background: transparent
      url("../images/solej/b66df0826ca93082d6f200f684587641.png") repeat-x;
  }

  .footer-details {
    background: $primaryColor
      url("../images/solej/51ef8d3cb7e7278d7a685017bcfaa92b.png");
  }

  .mobile-navigation {
    &__header {
      background: $primaryColor
        url("../images/solej/51ef8d3cb7e7278d7a685017bcfaa92b.png") repeat;
    }
  }
}
/* *** */

/* graywolf */
.solej-graywolf {
  $primaryColor: #af1a20;
  $borderColor: #df3a3a;

  background-image: url("../images/solej/29819d8523e60b87dea7b95c53d0b7b1.png");

  .header {
    .logo-container a,
    .restaurant-controls a,
    .restaurant-controls {
      color: #a2a2a2;
    }
  }

  .navigation > ul > li > a {
    color: rgba(51, 51, 51, 0.67);
    .caret {
      border-top-color: rgba(51, 51, 51, 0.67);
    }
  }

  .button.button-inverse {
    border-color: $borderColor;
  }

  .testimonials blockquote p {
    border-color: $borderColor;
  }

  .steps {
    h2,
    h3 {
      color: $primaryColor;
    }
    p {
      color: black;
    }
    .img-circle {
      background: transparent;
      border: 1px dashed $primaryColor;
    }
  }

  .hours {
    &,
    dd {
      color: #333333;
    }
    p.muted {
      color: #999;
    }
  }

  .promotion-box {
    .img-circle {
      border-color: $borderColor;
    }
  }

  .three-boxes .box-column {
    background: transparent;
    border: 1px dashed $borderColor;

    img {
      box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1);
    }

    h3,
    p {
      color: #333;
    }
  }

  .referral {
    .container {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: block;
        height: 7px;
        top: -7px;
        left: 15px;
        right: 15px;
        z-index: 0;
        background: url("../images/solej/a84423d5f84cb1ae0daf909a4cc504ce.png")
          0px 20px repeat;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        height: 7px;
        bottom: -7px;
        left: 15px;
        right: 15px;
        z-index: 0;
        background: url("../images/solej/a84423d5f84cb1ae0daf909a4cc504ce.png")
          0px 7px repeat;
      }

      & > div {
        position: relative;
        z-index: 1;
      }
    }

    .img-circle {
      border-color: $borderColor;
    }
  }

  .newsletter {
    .button {
      border: 1px solid white;
    }
    input {
      border: 1px solid $primaryColor;
    }
    h3 {
      color: #333;
    }
  }

  .footer-copy {
    color: #999;
  }

  #header {
    background: transparent
      url("../images/solej/a1f0534922fc700c2472370954899641.png") repeat-x;
  }

  #newsSlider .item:after,
  .funky-border:after {
    background: url("../images/solej/a84423d5f84cb1ae0daf909a4cc504ce.png") 0px
      7px repeat;
  }

  #newsSlider .item:before,
  .funky-border:before {
    background: url("../images/solej/a84423d5f84cb1ae0daf909a4cc504ce.png") 0px
      20px repeat;
  }

  .steps .step:after {
    background: transparent
      url("../images/solej/d60528355967d1cec460518e3599b19d.png");
  }

  .navbar-inverse {
    background: #0a5a1d
      url("../images/solej/74a8bb9a6dfc39ad0c25e9a7944f422c.png");
  }

  .menu-group-header {
    color: white;
    background: #0a5a1d
      url("../images/solej/74a8bb9a6dfc39ad0c25e9a7944f422c.png");
  }

  #footer {
    background: transparent
      url("../images/solej/a1f0534922fc700c2472370954899641.png") repeat-x;
  }

  .footer-details {
    background: $primaryColor
      url("../images/solej/74a8bb9a6dfc39ad0c25e9a7944f422c.png");
  }

  .mobile-navigation {
    &__header {
      background: $primaryColor
        url("../images/solej/74a8bb9a6dfc39ad0c25e9a7944f422c.png") repeat;
    }
  }
}
/* *** */

/* terra */
.solej-terra {
  $textColor: #62100d;
  $primaryColor: #de4619;

  background: black url("../images/solej/c611c2db784679adbad4835f66197f74.jpg")
    repeat;

  .hours {
    &,
    h5,
    p.muted {
      color: $textColor;
    }
  }
  .steps h2,
  .steps h3,
  .steps p,
  .restaurant-controls,
  .restaurant-controls a,
  .logo-container a {
    color: $textColor;
  }
  .steps .img-circle {
    border: 1px solid $textColor;
  }

  .navigation > ul > li > a {
    color: $textColor;

    &.active {
      color: black;
    }

    .caret {
      border-top-color: $textColor;
    }
  }

  .testimonials {
    .section-header {
      h2,
      p {
        text-shadow: 0 1px 3px black;
      }
    }

    blockquote p {
      border-color: #ff7200;
    }
  }

  .button.button-inverse {
    background: $textColor;
    box-shadow: 0 0 0 4px $textColor;
    border-color: #de4619;
  }

  .three-boxes .button.button-inverse {
    background: white;
    color: $primaryColor;
    box-shadow: 0 0 0 4px white;
    border-color: $primaryColor;
  }

  .newsletter {
    h3,
    i.icon-envelope-alt {
      color: $textColor;
    }
    input {
      border-color: $textColor;
    }
  }

  .funky-border {
    box-shadow: inset 0 1px 28px rgba(0, 0, 0, 0.57);
  }

  .m-indicators .m-indicators__btn.is-active {
    box-shadow: none;
    border-color: white;
    background: $primaryColor;
  }

  #header {
    background: transparent
      url("../images/solej/fded622b8d60e1f921ed53e7e3519c1e.png") repeat-x;
  }

  #newsSlider .item:after,
  .funky-border:after {
    background: url("../images/solej/8cc89eeca94835b130dd99e5dd9b18bd.png") 0px
      7px repeat;
  }

  #newsSlider .item:before,
  .funky-border:before {
    background: url("../images/solej/8cc89eeca94835b130dd99e5dd9b18bd.png") 0px
      20px repeat;
  }

  .steps .step:after {
    background: transparent
      url("../images/solej/16918af1f91b3cdc5ad58fc694287a14.png");
  }

  .navbar-inverse {
    background: #0a5a1d
      url("../images/solej/7a76b58695e772832562709fdcadf64a.png");
  }

  .menu-group-header {
    color: white;
    background: #0a5a1d
      url("../images/solej/7a76b58695e772832562709fdcadf64a.png");
  }

  #footer {
    background: transparent
      url("../images/solej/fded622b8d60e1f921ed53e7e3519c1e.png") repeat-x;
  }

  .footer-details {
    background: $primaryColor
      url("../images/solej/7a76b58695e772832562709fdcadf64a.png");
  }

  .mobile-navigation {
    &__header {
      background: $primaryColor
        url("../images/solej/7a76b58695e772832562709fdcadf64a.png") repeat;
    }
  }
}
/* *** */

/* dark */
.solej-dark {
  $background-color: #0c1117;

  background: $background-color;

  #header {
    background-image: url("../images/solej/9850bd31d77cfb208159907ec27743af.jpg");
  }

  .jumbotron {
    box-shadow: none;
    border: none;
  }
  .testimonials blockquote {
    background: $background-color;
  }
  .testimonials blockquote:after {
    border-color: $background-color transparent;
  }
  .box-column {
    background: #e7e8ec;
    color: #0f141a;
    h3 {
      color: #0f141a;
    }
  }

  .promotion-box .img-circle {
    background: $background-color;
    box-shadow: 0 0 0 3px $background-color;

    &.promo-badge {
      background: #1d3551;
      box-shadow: 0 0 0 3px #1d3551;
    }
  }

  .referral .img-circle {
    background: $background-color;
    box-shadow: 0 0 0 3px $background-color;
  }

  .content-background {
    background: $background-color;
  }

  .site-promotions ul.unstyled {
    background: #e7e8ec;
    color: #0b1014;
  }

  .site-promotions .promotion {
    background: #e7e8ec;
    color: #0b1014;

    .h2 {
      color: #0b1014;
    }

    .button {
      background: #1d3551;
      color: white;
      border-color: white;
      box-shadow: 0 0 0 4px #1d3551;
    }
  }

  .content-content {
    background: none;
  }

  .site-reviews ul.timeline {
    border-top-color: #bac3cc;
    &:before {
      background: #bac3cc;
    }
  }

  .site-reviews ul.timeline .review .review-content {
    background: #bac3cc;

    blockquote small {
      color: #1d3551;
    }
  }

  .steps {
    .step:after {
      background: transparent
        url("../images/solej/71fd27ee45ac4c7dc9ddde9fcc6114c0.png");
    }
  }

  #newsSlider .item:after,
  .funky-border:after,
  #newsSlider .item:before,
  .funky-border:before,
  .page-header.funky-border:before {
    display: none;
  }

  .navbar-inverse {
    padding-top: 0px;
    background: black
      url("../images/solej/b20706221872c1a5ae629b7c80ac64da.gif");
  }

  .page-header.funky-border:after {
    display: none;
  }

  a.close,
  button.close {
    color: #f3f3f3;
    opacity: 1;
    text-shadow: none;
  }

  .menu-group-header {
    color: white;
    background: black
      url("../images/solej/b20706221872c1a5ae629b7c80ac64da.gif");
  }
  #footer {
    padding-top: 0;
  }
  .product-name h3,
  .product-name h4 {
    color: #fcfcfc;
  }
  .table.menu thead {
    background: #181818;
  }
  .table thead th.size-info {
    color: #f3f3f3;
  }

  .table {
    color: #888;
  }

  .cart .header {
    background: #1f1f1f;
  }

  .footer-details {
    background: #1d3551
      url("../images/solej/b20706221872c1a5ae629b7c80ac64da.gif");
  }

  .popover {
    background: black;
    border: 1px solid #1d3551;
    box-shadow: 0 0 100px rgba(7, 92, 154, 0.5);
  }

  .cart .modal-header,
  .cart .modal-footer,
  .cart .modal-dialog {
    background: $background-color;
  }

  .creator .control-label {
    color: #b3b3b3;
    text-shadow: none;
  }

  .topping__count {
    color: #7ab800;
  }

  .topping__name {
    color: #b9b9b9;
  }

  .topping-item.is-selected {
    .topping {
      background: rgba(10, 151, 251, 0.17);
      border-color: #1d3551;
    }

    .topping__name {
      color: white;
    }
  }

  .topping-row .topping-item {
    background: transparent;
    border: 1px solid rgba(223, 223, 223, 0.11);
  }
  .card,
  .cart .modal-dialog,
  form.checkout section {
    background: #181818;
  }

  .site-promotions.menu-promotions .close {
    color: $text-color;
  }

  .m-group__header,
  .m-list__title {
    font-size: $font-size-h4; // reduce too big font-size (inherited from h3)
  }

  .m-item__title {
    font-size: $font-size-h5; // reduce too big font-size (inherited from h4)
  }

  .mobile-navigation {
    &__header {
      background: #0c1117;
    }
  }
}
/* *** */

.m-select {
  &__select {
    max-width: 100%;
  }
}

// Cover media
.m-cover-media {
  @include position(absolute, 0 0 0 0);
  overflow: hidden;

  &__item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%; // Compatibility with initial background-position value that has been used so far

    &--hero {
      object-position: 50% 50%;
    }
  }
}
