.language-select {
  a {
    text-decoration: none;
    cursor: pointer;
  }
  .locale-image {
    height: 1.2em;
  }
  .dropdown-menu {
    min-width: 60px;
  }
}
