.alert {
  padding: 15px;
  border-bottom: none;
  min-height: 0;
  font-size: 14px;
  position: relative;
  text-shadow: none;

  ul {
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    font-family: FontAwesome;
    text-decoration: inherit;
    font-size: 13px;
    line-height: 14px;
    display: block;
    speak: none;
    margin-right: 5px;
    border-radius: 100%;
    padding: 5px;
    top: -10px;
    left: -10px;
    box-shadow: 0 1px 0 rgba(0,0,0,0.3);
  }
}

.alert-error, .alert-danger {
  color: white;
  background: #d50000;
  margin-bottom: 15px;

  // NOTICE: I know it's not the best way to solve this but components/alerts is a legacy component and will be removed along with legacy themes
  .m-fulfillment-time__list:has(&) {
    margin-top: 15px;
  }

  &:before {
    font-family: FontAwesome;
    background: #d50000;
    content: "\e813";
  }
}
.alert-info  {
  background: #0091EA;
  color: white;

  // NOTICE: I know it's not the best way to solve this but components/alerts is a legacy component and will be removed along with legacy themes
  .m-fulfillment-time__list:has(&) {
    margin-top: 15px;
  }

  &:before {
    font-family: FontAwesome;
    background: #0091EA;
    content: "\e819";
  }
}

.error-list {
  list-style: none;
  padding-left: 0;
}
